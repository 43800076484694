import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
import { getAllRentalLocation } from 'features/rental-location/actions';
import Modals from '../Modals';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import IdentityImageSection from './identityImageSection';
import ReuploadReasonSection from './reuploadReasonSection';
import { createIdentityReview } from 'features/review-identity/actions';

const ReviewIdentityModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { setShowReviewIdentityModal, showReviewIdentityModal, showToast } = useAppContext();

  const orderData = useSelector(getDetailOrder);
  const rentalLocation = useSelector((state) => state.rentalLocation);

  const [section, setSection] = useState(1);
  const [notesValue, setNotesValue] = useState('');
  const [reasonsToReupload, setReasonsToReupload] = useState([
    {
      id: 1,
      value: `Foto ${showReviewIdentityModal.type.toUpperCase()} tidak jelas dan buram`,
      checked: false,
    },
    {
      id: 2,
      value: `Foto ${showReviewIdentityModal.type.toUpperCase()} gelap dan tidak jelas`,
      checked: false,
    },
  ]);

  const submitReviewIdentitiyHandler = async () => {
    const validNotesValue = notesValue.replace(/\s/, '').length !== 0;

    if (!validNotesValue) {
      return showToast({ type: 'error', message: 'Masukan Alasan Upload Ulang' });
    }

    const payload = {
      type: showReviewIdentityModal.type,
      order_id: orderData.transaction_key,
      message: notesValue,
    };

    const thisLocation = location.pathname.split('/')[1];
    const baliLocation = rentalLocation?.data.find((item) => item.name.toLowerCase() === 'bali')?.id;

    try {
      await dispatch(createIdentityReview(payload)).unwrap();
      showToast({ type: 'success', message: 'Berhasil mengubah status' });
      setShowReviewIdentityModal({ ...showReviewIdentityModal, show: false });
      navigate(`/${thisLocation}?status=ALL&locationId=${baliLocation}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  };

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  return (
    <Modals
      setState={() => setShowReviewIdentityModal({ ...showReviewIdentityModal, show: false })}
      title={`Tinjau ${showReviewIdentityModal.type.toUpperCase()}`}
      icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="review-identity-modal">
        {section === 1 ? (
          <IdentityImageSection setSection={setSection} />
        ) : (
          <ReuploadReasonSection
            setSection={setSection}
            notesValue={notesValue}
            setNotesValue={setNotesValue}
            reasonsToReupload={reasonsToReupload}
            setReasonsToReupload={setReasonsToReupload}
            onSubmit={submitReviewIdentitiyHandler}
          />
        )}
      </div>
    </Modals>
  );
};

export default ReviewIdentityModal;
