import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BookingZone, Button, InputField, SelectFieldAdditionalItem, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerData } from 'features/user/slice';
import countryCodes from 'utils/country-codes.json';
import DetailPenyewa from './detail-penyewa';
import DetailHari from './detail-hari';
import { useAppContext } from 'components/Context/AppContext';
import { fetchOrderById, fetchSummaryOrder } from 'features/orders/actions';
import RincianBiaya from './rincian-biaya';
import { getApprovalSettings, updateApprovalOrder } from 'features/approvals/actions';
import { getServices } from 'features/services/actions';
import { checkEmptyObject, checkPermission, setCurrency } from 'utils/functionality';
import { getVehiclesByFilter } from 'features/vehicle/action';
import { format, parseISO, subHours } from 'date-fns';
import InputAdditionals from 'components/Global/InputAdditionals';
import InputViolation from 'components/Global/InputViolation';
import moment from 'moment-timezone';
import { getAllAddonByParams, getOrderAddon } from 'features/addon/actions';
import { overtimeWithDriver } from '../../CustomOrderComponent/DetailSewa/WithDriverForm';

const INITIAL_STATE = {
  booking_price: 0,
  order_detail: {
    baggage: '',
    passenger_number: '',
    booking_zones: [],
    end_booking_date: '',
    end_booking_time: '',
    start_booking_date: '',
    start_booking_time: '',
    vehicle_id: 0,
    location_id: 0,
    loc_time_id: '',
  },
  order_violations: [],
  order_type_id: 0,
  deposit: 0,
  over_time_price: 0,
  over_time: 0,
};

const EditOrder = ({ transactionKey }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();
  const customer = useSelector(getCustomerData);
  const { data: rentalServicesData } = useSelector((state) => state.services);
  const { data: summaryData, status: summaryStatus } = useSelector((state) => state.summaryOrder);
  const approvalData = useSelector((state) => state.approvals.approvalSetting);
  const { data, status } = useSelector((state) => state.detailOrder);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { data: additionalData } = useSelector((state) => state.addon);
  const { data: orderAddons } = useSelector((state) => state.orderAddon);

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [orderData, setOrderData] = useState(INITIAL_STATE);
  const [locationData, setLocationData] = useState({
    loc_time_id: '',
    location_id: 0,
  });
  const [vehicleInput, setVehicleInput] = useState('');
  const [dayDuration, setDayDuration] = useState([]);
  const [durationRent, setDurationRent] = useState(0);
  const [startRentDate, setStartRentDate] = useState('');
  const [selectedCar, setSelectedCar] = useState(undefined);
  // ADDITIONAL STATE
  const [additionalList, setAdditionalList] = useState([]);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  // VIOLIATION STATE
  const [violationList, setViolationList] = useState([]);
  const [violationPrice, setViolationPrice] = useState(0);
  const [baggage, setBaggage] = useState(0);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [selectedAdditionalItem, setSelectedAdditionalItem] = useState(undefined);
  const [additionalSearchInput, setAdditionalSearchInput] = useState('');

  const isUpdate = searchParams.get('update');
  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);

  const getTotalPrice = (total, point, voucher) => {
    let finalPrice = total - point;
    const voucherValue = voucher?.length > 0 ? voucher[0].value : undefined;
    if (voucherValue) {
      finalPrice -= voucherValue;
    }
    return isNaN(finalPrice) ? 0 : finalPrice;
  };

  useEffect(() => {
    if (selectedCar) {
      setIsPageLoaded(true);
    }
  }, [selectedCar]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  useEffect(() => {
    dispatch(fetchOrderById(transactionKey));
    dispatch(getOrderAddon(transactionKey));
  }, [transactionKey]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'order_additional_fees')) {
      if (data.order_additional_fees !== null) {
        setAdditionalList(data.order_additional_fees);
        setAdditionalPrice(0);
      }
    }
  }, [data]);

  useEffect(() => {
    if (additionalList.length > 0) {
      setAdditionalPrice(() => additionalList.map((item) => item.value).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setAdditionalPrice(0);
  }, [additionalList]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'order_violations')) {
      if (data.order_violations !== null) {
        setViolationList(data.order_violations);
        setViolationPrice(0);
      }
    }
  }, [data]);

  useEffect(() => {
    if (violationList.length > 0) {
      setViolationPrice(() => violationList?.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setViolationPrice(0);
  }, [violationList]);

  useEffect(() => {
    if (checkEmptyObject(data) || isPageLoaded) return;
    setSelectedCar(data.order_detail.vehicle);
  }, [data, isPageLoaded]);

  useEffect(() => {
    if (!isUpdate) return;
    if (checkEmptyObject(data)) return;
    const approvalKey = data.is_admin_creation ? 'update-order-to-higher-level' : 'update-order-to-customer';
    dispatch(getApprovalSettings({ limit: 10, key: approvalKey }));
    dispatch(getServices());
  }, [isUpdate, data]);

  useEffect(() => {
    if (status === 'loading' || summaryStatus === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status, summaryStatus]);

  useEffect(() => {
    if (!checkEmptyObject(data)) {
      setStartRentDate(parseISO(data.order_detail.start_booking_date || ''));
      setDurationRent(data.order_detail.booking_zones?.length || 0);
      setBaggage(data.order_detail?.baggage);
      setDayDuration(
        data.order_detail.booking_zones?.map((item) => {
          return {
            ...item,
            overtime_duration: overtimeWithDriver.find((ovt) => ovt.value === item.overtime_duration)?.id,
            booking_end_time: item.booking_end_time.slice(0, 5),
            booking_start_time: item.booking_start_time.slice(0, 5),
          };
        }) || [],
      );
      setLocationData({ loc_time_id: data.order_detail.loc_time_id, location_id: data.order_detail.location_id });
      setOrderData({
        ...data,
        order_violations: data.order_violations?.length > 0 ? data.order_violations : [],
        order_detail: {
          ...data.order_detail,
          start_booking_time: data.order_detail.start_booking_time.slice(0, 5),
          end_booking_time: data.order_detail.end_booking_time.slice(0, 5),
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (!rentalServicesData.length) return;
    if (checkEmptyObject(data) || !isUpdate) return;
    const subServiceWithDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'With Driver');

    dispatch(
      getVehiclesByFilter({
        subServiceId: subServiceWithDriver?.id,
        order_id: orderData?.transaction_key,
        limit: 0,
        supportDriver: true,
        customOrder: true,
        startTrip: `${format(
          parseISO(
            orderData?.order_detail?.start_booking_date
              ? orderData.order_detail.start_booking_date
              : data.order_detail.start_booking_date,
          ),
          'yyyy-MM-dd',
        )} ${
          orderData?.order_detail?.start_booking_time
            ? orderData.order_detail.start_booking_time
            : data.order_detail.start_booking_time.slice(0, 5)
        }`,
        endTrip: `${format(
          parseISO(
            orderData?.order_detail?.end_booking_date
              ? orderData.order_detail.end_booking_date
              : data.order_detail.end_booking_date,
          ),
          'yyyy-MM-dd',
        )} ${
          orderData?.order_detail?.end_booking_time
            ? orderData.order_detail.end_booking_time
            : data.order_detail.end_booking_time.slice(0, 5)
        }`,
        locationRental: orderData?.order_detail?.location_id
          ? orderData.order_detail.location_id
          : data.order_detail.location_id,
      }),
    );
    dispatch(
      getAllAddonByParams({
        locationId: orderData?.order_detail?.location_id
          ? orderData.order_detail.location_id
          : data.order_detail.location_id,
        locTimeId: orderData?.order_detail?.loc_time_id
          ? orderData.order_detail.loc_time_id
          : data.order_detail.loc_time_id,
        startDate: orderData.order_detail.start_booking_date,
        endDate: orderData.order_detail.end_booking_date,
        startTime: orderData.order_detail.start_booking_time + ':00',
        endTime: orderData.order_detail.end_booking_time + ':00',
        limit: 0,
      }),
    );
  }, [data, isUpdate, orderData, rentalServicesData]);

  const getAddonsData = (addonsId) => additionalData?.data?.find((item) => item.id === addonsId);

  useEffect(() => {
    if (!orderAddons?.length) return;
    if (checkEmptyObject(additionalData)) return;
    const mappedAddons = orderAddons.map((item) => ({
      additional: getAddonsData(item.id) === undefined ? item : getAddonsData(item.id),
      total: item.varieties[0].quantity,
    }));
    setSelectedAdditionalItem(mappedAddons);
  }, [orderAddons, additionalData]);

  useEffect(() => {
    if (!isPageLoaded) return;
    if (
      orderData.order_detail.end_booking_date === '' ||
      orderData.order_detail.end_booking_time === '' ||
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      !rentalServicesData.length ||
      !selectedCar?.id ||
      !dayDuration.length
    )
      return;
    const subServiceWithDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'With Driver');

    const mappedBookingZone = orderData?.order_detail?.booking_zones.map((item, idx) => ({
      ...item,
      day: idx + 1,
      booking_end_time: dayDuration[idx].booking_end_time,
      booking_start_time: dayDuration[idx].booking_start_time,
      overtime_duration: overtimeWithDriver.find((ovt) => ovt.id === dayDuration[idx].overtime_duration)?.value,
    }));

    let mappingAdditionalItem = [];

    if (selectedAdditionalItem?.length > 0) {
      for (let additionalChild of selectedAdditionalItem) {
        const additionalObj = {
          id: additionalChild.additional.id,
          varieties: [
            {
              id: additionalChild.additional.varieties[0].id,
              quantity: additionalChild.total,
            },
          ],
        };

        mappingAdditionalItem.push(additionalObj);
      }
    }

    dispatch(
      fetchSummaryOrder({
        end_booking_date: orderData?.order_detail.end_booking_date,
        end_booking_time: mappedBookingZone
          ? mappedBookingZone[mappedBookingZone?.length - 1]?.booking_end_time
          : orderData?.order_detail.end_booking_time,
        start_booking_date: orderData?.order_detail.start_booking_date,
        start_booking_time: orderData?.order_detail.start_booking_time,
        pasengger_number: orderData?.order_detail.passenger_number,
        deposit: orderData?.deposit,
        deposit_e_toll: orderData?.deposit_e_toll,
        order_type_id: 1,
        vehicle_id: selectedCar?.id,
        without_driver: 0,
        order_booking_zone: mappedBookingZone,
        promotion_id: selectedCar?.promo?.promo_id,
        vehicle_category_id: selectedCar.category?.id,
        location_id: orderData?.order_detail.location_id,
        point: orderData?.point,
        sub_services_id: subServiceWithDriver?.id,
        order_additional_fees: additionalList,
        violations: violationPrice,
        order_id: orderData.id,
        addons: mappingAdditionalItem,
      }),
    );
  }, [
    orderData,
    selectedCar,
    rentalServicesData,
    additionalList,
    dayDuration,
    violationPrice,
    isPageLoaded,
    selectedAdditionalItem,
  ]);

  const backHandler = () => {
    navigate(-1);
  };

  const updateHandler = () => {
    if (
      orderData.order_detail.end_booking_date === '' ||
      orderData.order_detail.end_booking_time === '' ||
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      isNaN(baggage) ||
      baggage === '' ||
      isNaN(orderData.order_detail.passenger_number) ||
      orderData.order_detail.passenger_number === '' ||
      !orderData.order_detail.booking_zones.length ||
      !orderData.order_detail.vehicle_id
    ) {
      return showToast({ type: 'warning', message: 'Harap Mengisi Semua Field!' });
    }

    delete orderData.order_detail.identity;

    const utcDifference = moment
      .tz(`${orderData.order_detail.start_booking_date}`, orderData.order_detail.loc_time_id)
      .format('Z')
      .split(':')[0];

    const convertStartBookingTimeUTC = subHours(
      parseISO(`${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`),
      parseInt(utcDifference),
    );

    const convertEndBookingTimeUTC = subHours(
      parseISO(
        `${orderData.order_detail.end_booking_date} ${
          overtimeWithDriver.find((ovt) => ovt.id === dayDuration[dayDuration.length - 1].overtime_duration)?.value > 0
            ? `${
                dayDuration[dayDuration.length - 1].booking_end_time.split(':')[0] -
                overtimeWithDriver.find((ovt) => ovt.id === dayDuration[dayDuration.length - 1].overtime_duration)
                  ?.value
              }:${dayDuration[dayDuration.length - 1].booking_end_time.split(':')[1]}`
            : dayDuration[dayDuration.length - 1]?.booking_end_time
        }`,
      ),
      parseInt(utcDifference),
    );

    const mappedBookingZone = summaryData.order_zone_price.map((item, idx) => {
      const convertStartTimeUTC = subHours(
        parseISO(`${orderData.order_detail.booking_zones[idx].date} ${item.booking_start_time}`),
        parseInt(utcDifference),
      );

      const convertEndTimeUTC = subHours(
        parseISO(
          `${orderData.order_detail.booking_zones[idx].date} ${
            item.overtime_duration > 0
              ? `${item.booking_end_time.split(':')[0] - item.overtime_duration}:${item.booking_end_time.split(':')[1]}`
              : item.booking_end_time
          }`,
        ),
        parseInt(utcDifference),
      );

      return {
        ...item,
        date: format(convertStartTimeUTC, 'yyyy-MM-dd'),
        booking_start_time: format(convertStartTimeUTC, 'HH:mm'),
        booking_end_time: format(convertEndTimeUTC, 'HH:mm'),
        pickup_zone_id: orderData.order_detail.booking_zones[idx].pickup_zone_id,
        pickup_list_zone_id: orderData.order_detail.booking_zones[idx].pickup_list_zone_id,
        detail_pickup_location: orderData.order_detail.booking_zones[idx].detail_pickup_location,
        drop_off_zone_id: orderData.order_detail.booking_zones[idx].drop_off_zone_id,
        drop_off_list_zone_id: orderData.order_detail.booking_zones[idx].drop_off_list_zone_id,
        detail_drop_off_location: orderData.order_detail.booking_zones[idx].detail_drop_off_location,
        driving_zone_id: orderData.order_detail.booking_zones[idx].driving_zone_id,
        driving_list_zone_id: 0,
        detail_driving_location: orderData.order_detail.booking_zones[idx].detail_driving_location,
        is_driver_stay_overnight: orderData.order_detail.booking_zones[idx].is_driver_stay_overnight,
      };
    });

    const priceDifference = orderData.total_amount_order - summaryData.total_payment;

    let mappingAdditionalItem = [];

    if (selectedAdditionalItem?.length > 0) {
      for (let additionalChild of selectedAdditionalItem) {
        const additionalObj = {
          ...additionalChild.additional,
          varieties: [
            {
              ...additionalChild.additional.varieties[0],
              quantity: additionalChild.total,
            },
          ],
        };

        mappingAdditionalItem.push(additionalObj);
      }
    }

    const payload = {
      reference_id: orderData.id,
      reference_type: 'order',
      approval_setting_id: approvalData.data[0].id,
      data: {
        ...orderData,
        disbursement: {
          ...orderData.disbursement,
          payment_method_id: 0,
          ppn_internal_percentage: 0,
          disbursement_confirmation_image: '',
          payment_platform_fee_type_fixed: 0,
          total_amount_customer_service_fee:
            orderData.total_amount_order === summaryData.total_payment
              ? orderData.disbursement.total_amount_customer_service_fee
              : 0,
          total_amount_external_payment_fee:
            orderData.total_amount_order === summaryData.total_payment
              ? orderData.disbursement.total_amount_external_payment_fee
              : 0,
          payment_platform_fee_type_percentage: 0,
          total_amount_with_customer_service_fee:
            orderData.total_amount_order === summaryData.total_payment || priceDifference > 0
              ? orderData.disbursement.total_amount_with_customer_service_fee
              : 0,
          total_net_amount:
            orderData.total_amount_order === summaryData.total_payment ? orderData.disbursement.total_net_amount : 0,
        },
        booking_price: summaryData.booking_price,
        deposit: summaryData.deposit,
        price_per_day: summaryData.price_per_day,
        total_payment:
          orderData.total_amount_order === summaryData.total_payment
            ? getTotalPrice(orderData?.total_payment, orderData?.point, orderData?.vouchers)
            : getTotalPrice(summaryData?.total_payment, orderData?.point, orderData?.vouchers),
        deposit_e_toll: summaryData.deposit_e_toll,
        outside_operational_charge: summaryData.outside_operational_charge,
        one_day_order_charge: summaryData.one_day_order_charge,
        exceed_passenger_price: summaryData.exced_max_passenger_charge,
        over_time_price: summaryData.over_time_price,
        over_time: summaryData.over_time_hour,
        order_overtime_total_price: summaryData.order_overtime_total_price,
        remainder: orderData.type === 'FULL' ? 0 : summaryData.remainder,
        down_payment: orderData.type === 'FULL' ? 0 : summaryData.total_dp,
        total_amount_order:
          orderData.total_amount_order === summaryData.total_payment
            ? orderData.total_amount_order
            : summaryData.total_payment,
        total_diff_price_with_approval: 0,
        order_detail: {
          ...orderData.order_detail,
          baggage: baggage,
          vehicle_id: selectedCar.id,
          booking_zones: mappedBookingZone,
          start_booking_date: format(convertStartBookingTimeUTC, 'yyyy-MM-dd'),
          start_booking_time: format(convertStartBookingTimeUTC, 'HH:mm'),
          end_booking_date: format(convertEndBookingTimeUTC, 'yyyy-MM-dd'),
          end_booking_time: format(convertEndBookingTimeUTC, 'HH:mm'),
          vehicle: selectedCar,
        },
        order_additional_fees: additionalList,
        order_violations: violationList,
        addon_total_price: summaryData.addon_total_price,
        addons: mappingAdditionalItem,
      },
    };

    setShowConfirmation({
      message: 'Apakah anda yakin ingin melanjutkan proses recalculate?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(updateApprovalOrder(payload)).unwrap();
          showToast({ type: 'success', message: 'Approval recalculate berhasil dilakukan' });
          navigate(-1);
        } catch (err) {
          showToast({ type: 'error', message: 'Approval recalculate gagal dilakukan' });
        }
      },
    });
  };

  return (
    <div className="detail-order-with-driver">
      <div className="detail-order-with-driver__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Dengan Supir">
        <DetailPenyewa orderData={orderData} customer={customer} selectedCountry={selectedCountry} />
        <DetailHari
          dayDuration={dayDuration}
          setDayDuration={setDayDuration}
          durationRent={durationRent}
          setDurationRent={setDurationRent}
          orderData={orderData}
          setOrderData={setOrderData}
          startRentDate={startRentDate}
          setStartRentDate={setStartRentDate}
          setSelectedCar={setSelectedCar}
        />
        <RincianBiaya
          orderData={orderData}
          setOrderData={setOrderData}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
          vehicleInput={vehicleInput}
          setVehicleInput={setVehicleInput}
          summaryData={summaryData}
          baggage={baggage}
          setBaggage={setBaggage}
        />

        <div className="detail-order-with-driver__rent-zone">
          <div className="detail-order-with-driver__rent-detail-title">
            <h1>Zona Penyewaan</h1>
          </div>
          <BookingZone
            startDate={startRentDate}
            durationRentDate={durationRent}
            selectedCar={selectedCar}
            orderData={orderData}
            setOrderData={setOrderData}
            dayDuration={dayDuration}
            forCustomOrder
            locTimeId={locationData.loc_time_id}
            rentalLocationId={locationData.location_id}
          />
        </div>

        <div className="detail-order-with-driver__additional-item">
          <h2>Additional</h2>
          <div className="wrapper">
            <SelectFieldAdditionalItem
              label="Pilih Tambahan"
              id="tambahan"
              className="additional"
              placeholder="Cari Perlengkapan Khusus.."
              selectedAdditionalItem={selectedAdditionalItem}
              setSelectedAdditionalItem={setSelectedAdditionalItem}
              value={additionalSearchInput}
              onChange={(e) => setAdditionalSearchInput(e.target.value)}
              data={additionalData?.data || []}
              disable={!additionalData || checkEmptyObject(additionalData)}
              filterParams={{
                locationRental: orderData?.order_detail?.location_id,
                locTimeId: orderData?.order_detail?.loc_time_id,
                startDate: orderData?.order_detail?.start_booking_date,
                endDate: orderData?.order_detail?.end_booking_date,
                startTime: orderData?.order_detail?.start_booking_time + ':00',
                endTime: orderData?.order_detail?.end_booking_time + ':00',
              }}
            />

            <InputField
              label="Total Biaya Permintaan Khusus"
              placeholder="Rp 0"
              value={summaryData?.addon_total_price ? setCurrency(summaryData.addon_total_price) : ''}
              disable
            />

            {/* <TextAreaInput
            label="Detail Tambahan"
            htmlFor="detail-tambahan"
            placeholder="Tulis Keterangan"
            value={descriptionAdditionalItem}
            onChange={(e) => setDescriptionAdditionalItem(e.target.value)}
            disable={isOrderConfirmation}
          /> */}
          </div>
        </div>

        <div className="detail-order-with-driver__price-detail-body__input-violation">
          <InputViolation
            violationList={violationList}
            setViolationList={setViolationList}
            totalPrice={violationPrice}
            isOnEdit={true}
            data={[]}
            violationPrice={0}
          />
        </div>

        <div className="detail-order-with-driver__price-detail-body__input-additional">
          <InputAdditionals
            additionalList={additionalList}
            setAdditionalList={setAdditionalList}
            totalPrice={additionalPrice}
            isOnEdit={true}
            data={[]}
            additionalPrice={0}
          />
        </div>

        <div className="detail-order-with-driver__total-price">
          <div className="total-price-wrapper">
            <h3>Total</h3>
            <p>{setCurrency(getTotalPrice(summaryData?.total_payment, orderData?.point, orderData?.vouchers))}</p>
          </div>
        </div>

        <div className="detail-order-with-driver__transaction-buttons">
          <Button variant="outline" className="button btn-cancel" width={208} size="sm" onClick={backHandler}>
            Batal
          </Button>

          {checkPermission(offCanvasMenu, currentMenu, 'update') && (
            <Button className="button" width={208} size="sm" onClick={updateHandler}>
              Recalculate
            </Button>
          )}
        </div>
      </TableWrapper>
    </div>
  );
};

export default EditOrder;
