import { Button } from 'components/Global';
import CheckboxField from 'components/Global/CheckboxField';
import TextAreaInput from 'components/Global/TextArea';
import React from 'react';

const ReuploadReasonSection = ({
  setSection,
  notesValue,
  setNotesValue,
  reasonsToReupload,
  setReasonsToReupload,
  onSubmit,
}) => {
  const clickReasonHandler = (e) => {
    const checkedValue = e.target.checked;
    const reasonId = e.target.id;

    const choosedReason = reasonsToReupload.find((item) => item.id == reasonId);

    if (choosedReason) {
      const newReasons = reasonsToReupload.map((item) => {
        if (item.id === choosedReason.id) {
          return {
            ...item,
            checked: checkedValue,
          };
        }

        return item;
      });

      setReasonsToReupload(newReasons);

      if (checkedValue) {
        setNotesValue((prevState) => {
          if (prevState) {
            if (prevState.includes(',')) {
              return prevState + ` ${choosedReason.value},`;
            } else {
              return prevState + `, ${choosedReason.value},`;
            }
          }

          return `${choosedReason.value},`;
        });
      } else {
        const splitedNotes = notesValue.split(',');

        const filteredNotes = splitedNotes.filter((item) => !item.includes(choosedReason.value)).join(',');
        setNotesValue(filteredNotes);
      }
    }
  };

  return (
    <div className="reupload-reason-section">
      <h3>Alasan Upload Ulang Identitas Penyewa</h3>

      <ul>
        {reasonsToReupload.map((item) => (
          <li key={item.id}>
            <CheckboxField
              label={item.value}
              value={item.value}
              htmlfor={item.id}
              checked={item.checked}
              onChange={clickReasonHandler}
            />
          </li>
        ))}
      </ul>

      <TextAreaInput
        label="Keterangan"
        htmlFor="keterangan"
        placeholder="Tulis Keterangan"
        className="notes"
        value={notesValue}
        onChange={(e) => setNotesValue(e.target.value)}
      />

      <div className="reupload-reason-section__button-action">
        <Button variant="outline" onClick={() => setSection((prevState) => prevState - 1)}>
          Back
        </Button>
        <Button onClick={onSubmit}>Submit</Button>
      </div>
    </div>
  );
};

export default ReuploadReasonSection;
